import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';

import { actions } from 'features/Game';

import Button from 'components/Button';
import SwitchBox from 'components/SwitchBox';
import Input from 'components/Input';
import minusPng from './img/minus.png';
import plusPng from './img/plus.png';
import arrowUpPng from './img/arrow-up.png';
import arrowDownPng from './img/arrow-down.png';
import './Basket.scss';
import { useMemo } from 'react';

const Basket = ({ index }) => {
  const dispatch = useDispatch();
  const limits = useSelector(state => state.game.limits);
  const [isPlaced, setPlaced] = useState(false);
  const [isAutoBet, setAutoBet] = useState(false);
  const [isAutoCashout, setAutoCashout] = useState(false);
  const [amount, setAmount] = useState(0);
  const [isBlock, setBlock] = useState(false);
  const [coef, setCoef] = useState(5);
  const [isNextBet, setNextBet] = useState(false);
  const [isOpenFull, setOpenFull] = useState(true);
  const currency = useSelector(state => state.auth.currency);
  const tempCoef = useSelector(state => state.game.coef);
  const locale = useSelector(state => state.locale.locale);
  const lang = useSelector(state => state.locale.lang);
  const platform = useSelector(state => state.auth.platform);
  const balance = useSelector(state => state.auth.balance);
  const periodType = useSelector(state => state.game.periodType);
  const isProcess = useSelector(state => state.game.isProcess);
  const tempBet = useSelector(state => state.game.cashedBets)?.find(t => t.bet_id === isPlaced);
  const b = block(platform === 'mobile' ? 'basket' : 'basket-desktop');

  // Mobile open full basket
  const switchOpenBasket = useCallback(() => setOpenFull(!isOpenFull), [isOpenFull]);

  // Reset bet
  useEffect(() => {
    if (tempBet && isPlaced) {
      setPlaced(false)
    }
  }, [isPlaced, tempBet])

  // Set block
  useEffect(() => {
    if (isPlaced || isProcess || isNextBet) {
      setBlock(true)
    } else {
      setBlock(false)
    }
  }, [isPlaced, isProcess, isNextBet])

  // Set limits
  useEffect(() => setAmount(limits.min), [limits.min])

  // Place bet function
  const placeBet = useCallback(() => dispatch(actions.placeBet(+amount, +coef, isAutoCashout, setPlaced, isAutoBet, index)),
    [amount, coef, dispatch, isAutoBet, isAutoCashout, index]);

  // Cashout money function
  const cashoutBet = useCallback(() => {
    dispatch(actions.cashoutBet(isPlaced, setPlaced))
  },
    [dispatch, isPlaced]);

  // Auto bet
  useEffect(() => {
    if (periodType === 2) setPlaced(false);
    if (periodType === 2 && isAutoBet) {
      placeBet();
    }
    if (periodType === 2 && isNextBet) {
      setNextBet(false);
      placeBet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodType])

  // For validate amount
  const validateChangeAmount = useCallback(value => {
    if (!isBlock) {
      if (value > limits.max) setAmount(+limits.max.toFixed(1))
      else if (value < limits.min) setAmount(+limits.min.toFixed(1))
      else if (coef * value > limits.win) setAmount(+(limits.win / coef).toFixed(1))
      else if (+(+value).toFixed(1) > balance) {
        if (balance < limits.min) {
          setAmount(+limits.min.toFixed(1))
        } else {
          setAmount(Math.floor(balance * 10, 1) / 10)
        }
      }
      else setAmount(+(+value).toFixed(1));
    }
  }, [balance, coef, isBlock, limits.max, limits.min, limits.win])

  // For validate input Amount
  const validateTempAmount = useCallback(value => {
    if (!isBlock) {
      if ((!isNaN(+value) || value.toString().indexOf('.') === value.toString().length - 1) &&
        value.toString().split('').filter(t => t === '.').length < 2) {
        if (value.toString().split('')[0] === '.') {
          setAmount(value.substring(1, value.length))
        } else setAmount(value)
      }
    }
  }, [isBlock])

  // For validate input coef
  const validateChangeCoef = value => {
    if (!isBlock) {
      if ((!isNaN(+value) || value.toString().indexOf('.') === value.toString().length - 1) &&
        value.toString().split('').filter(t => t === '.').length < 2) {
        if (value < 1.01 && value !== '' && +value !== 1) setCoef(1.01)
        else if (amount * value > limits.win) setCoef(limits.win / amount)
        else setCoef(value);
      }
    }
  }

  // For validate after blur coef
  const validateBlurCoef = value => {
    if (!isBlock) {
      if (+value > 1.1) {
        setCoef(+(+value).toFixed(2))
      } else {
        setCoef(1.01)
      }
    }
  }

  // For keyboard validate coef
  const keyboardCoefValidate = value => {
    if ((!isNaN(+value) || value.toString().indexOf('.') === value.toString().length - 1) &&
      value.toString().split('').filter(t => t === '.').length < 2) {
      if (value < 1.01) validateBlurCoef(1.01)
      else if (amount * value > limits.win) validateBlurCoef(limits.win / amount)
      else validateBlurCoef(value);
    }
  }

  // Set button click func
  const tempFunc = useMemo(() => {
    if (isPlaced) {
      if (periodType === 1) {
        return cashoutBet
      } else {
        return f => f
      }
    }
    if (!isPlaced) {
      if (periodType === 1 && !isNextBet) {
        return () => setNextBet(true);
      } if (periodType === 1 && isNextBet) {
        return () => setNextBet(false);
      } else {
        return placeBet
      }
    }
  },
    [isPlaced, periodType, cashoutBet, placeBet, isNextBet]
  )

  // Set button text
  const tempButtonText = useMemo(() => {
    if (isPlaced) {
      return <div className={b('cashout-bet')}>
        <div className={b('cashout-text')}>{locale.cashout}</div>
        <div className={b('cashout-content')}>
          <span className={b('cashout-amount')}>{`${periodType === 1 ? (tempCoef * amount).toFixed(2) : amount.toFixed(2)} `}</span>
          <span className={b('cashout-currency')}>{currency}</span>
        </div>
      </div>
    }
    if (!isPlaced) {
      if (periodType !== 1) {
        return locale.placeBet
      } else if (isNextBet) {
        return locale.cancelBet
      } else {
        return locale.placeBetNextRound
      }
    }
  },
    [isPlaced, periodType, locale, tempCoef, amount, currency, isNextBet, b]
  )

  return platform === 'mobile' ?
    // MOBILE
    <article className={b({ isBlock })}>
      {isOpenFull && <div className={b('rows', { isBlock })}>
        <div className={b('row')}>
          <div className={b('switchbox-wrapper')}>
            <SwitchBox value={isAutoBet} changeValue={value => isBlock ? isBlock : setAutoBet(value)} text="AUTO" />
          </div>
          <div className={b('change-block')}>
            <div className={b('title')}>{locale.bet}</div>
            <div className={b('change-elements')}>
              <div className={b('input')}>
                <Input
                  value={amount}
                  onChange={e => validateTempAmount(e.currentTarget.value)}
                  onBlur={() => validateChangeAmount(amount)}
                  unBorder />
                <div className={b('overlay')}>
                  <div className={b('input-val')}>{amount}</div>
                  <div className={b('input-val-ending')}>{currency}</div>
                </div>
              </div>
              <div className={b('change-button')} onClick={() => validateChangeAmount(amount - limits.min)}>
                <img src={minusPng} alt="" />
              </div>
              <div className={b('change-button')} onClick={() => validateChangeAmount(amount + limits.min)}>
                <img src={plusPng} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className={b('row')}>
          <div className={b('switchbox-wrapper')}>
            <SwitchBox value={isAutoCashout} changeValue={value => isBlock ? isBlock : setAutoCashout(value)} text="AUTO" />
          </div>
          <div className={b('change-block')}>
            <div className={b('title')}>{locale.collect}</div>
            <div className={b('change-elements')}>
              <div className={b('input')}>
                <Input
                  value={coef}
                  onChange={e => validateChangeCoef(e.currentTarget.value)}
                  onBlur={() => validateBlurCoef(coef)}
                  unBorder />
                <div className={b('overlay')}>
                  <div className={b('input-val')}>{coef}</div>
                  <div className={b('input-val-ending', { color: 'green' })}>x</div>
                </div>
              </div>
              <div className={b('change-button')} onClick={() => keyboardCoefValidate(+coef - 0.25)}>
                <img src={arrowDownPng} alt="" />
              </div>
              <div className={b('change-button')} onClick={() => keyboardCoefValidate(+coef + 0.25)}>
                <img src={arrowUpPng} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>}
      <div className={b('toggle')}>
        <div className={b('toggle-btn', { isOpenFull })} onClick={switchOpenBasket} />
      </div>
      <div className={b('button-layout')}>
        <Button
          text={tempButtonText}
          size='container'
          fontSize="large"
          isDisabled={isProcess}
          color={isNextBet ? 'yellow' : isPlaced ? 'red' : 'green'}
          isBlock={isBlock}
          isRobotoFont={!isPlaced && periodType === 1}
          isBold={!isPlaced && periodType === 1}
          callBack={tempFunc} />
      </div>
    </article> :
    // DESKTOP
    <article className={b({ isBlock, lang })}>
      <div className={b('container')}>
        <div className={b('content', { isBlock })}>
          <div className={b('content-amounts',)}>
            <div className={b('amount-button')} onClick={() => validateChangeAmount(5)}>
              5.00
            </div>
            <div className={b('amount-button')} onClick={() => validateChangeAmount(10)}>
              10.00
            </div>
            <div className={b('amount-button')} onClick={() => validateChangeAmount(20)}>
              20.00
            </div>
            <div className={b('amount-button')} onClick={() => validateChangeAmount(balance)}>
              {locale.allIn}
            </div>
          </div>
        </div>
        <div className={b('rows', { isBlock })}>
          <div className={b('row')}>
            <div className={b('switchbox-wrapper')}>
              <SwitchBox value={isAutoBet} changeValue={value => isBlock ? isBlock : setAutoBet(value)} text="AUTO" />
            </div>
            <div className={b('change-block')}>
              <div className={b('title')}>{locale.bet}</div>
              <div className={b('change-elements')}>
                <div className={b('input')}>
                  <Input
                    value={amount}
                    onChange={e => validateTempAmount(e.currentTarget.value)}
                    onBlur={() => validateChangeAmount(amount)}
                    unBorder />
                  <div className={b('overlay')}>
                    <div className={b('input-val')}>{amount}</div>
                    <div className={b('input-val-ending')}>{currency}</div>
                  </div>
                </div>
                <div className={b('change-button')} onClick={() => validateChangeAmount(amount - limits.min)}>
                  <img src={minusPng} alt="" />
                </div>
                <div className={b('change-button')} onClick={() => validateChangeAmount(amount + limits.min)}>
                  <img src={plusPng} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className={b('row')}>
            <div className={b('switchbox-wrapper')}>
              <SwitchBox value={isAutoCashout} changeValue={value => isBlock ? isBlock : setAutoCashout(value)} text="AUTO" />
            </div>
            <div className={b('change-block')}>
              <div className={b('title')}>{locale.collect}</div>
              <div className={b('change-elements')}>
                <div className={b('input')}>
                  <Input
                    value={coef}
                    onChange={e => validateChangeCoef(e.currentTarget.value)}
                    onBlur={() => validateBlurCoef(coef)}
                    unBorder
                  />
                  <div className={b('overlay')}>
                    <div className={b('input-val')}>{coef}</div>
                    <div className={b('input-val-ending', { color: 'green' })}>x</div>
                  </div>
                </div>
                <div className={b('change-button')} onClick={() => keyboardCoefValidate(+coef - 0.25)}>
                  <img src={arrowDownPng} alt="" />
                </div>
                <div className={b('change-button')} onClick={() => keyboardCoefValidate(+coef + 0.25)}>
                  <img src={arrowUpPng} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={b('button-layout')}>
        <Button
          text={tempButtonText}
          size='container'
          fontSize="large"
          isDisabled={isProcess}
          color={isNextBet ? 'yellow' : isPlaced ? 'red' : 'green'}
          isBlock={isBlock}
          isRobotoFont={!isPlaced && periodType === 1}
          isBold={!isPlaced && periodType === 1}
          callBack={tempFunc} />
      </div>
    </article>
}

export default Basket;