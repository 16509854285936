import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';

import { actions as historyActions } from 'features/History';

import Firework from './Firework';
import Pause from './Pause';

import styles from '../../data';

import './GameWindow.scss';

const b = block('game-window');

const GameWindow = () => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const [isPause, setPause] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const periodType = useSelector(state => state.game.periodType);
  const styleCode = useSelector(state => state.game.styleCode);
  const [leftParam, setLeftParam] = useState(0);
  const [topParam, setTopParam] = useState(0);

  const setRandomLeft = useCallback(() => {
    if (containerRef.current) {
      const randomLeft = containerRef.current.clientWidth / 1.5 * Math.random();
      setLeftParam(Math.floor(randomLeft));
    }
    setTimeout(() => setRandomLeft(), Math.floor(1500 + 3e3 * Math.random()))
  }, [])

  const setRandomTop = useCallback(() => {
    if (containerRef.current) {
      const randomTop = containerRef.current.clientHeight / 2 * Math.random();
      setTopParam(Math.floor(randomTop));
    }
    setTimeout(() => setRandomTop(), Math.floor(2e3 + 4e3 * Math.random()))
  }, [])

  useEffect(() => {
    setRandomLeft();
    setRandomTop();
  }, [setRandomLeft, setRandomTop])

  useEffect(() => {
    if (periodType === 2) {
      setPause(false)
      dispatch(historyActions.clearBets());
      if (!timeoutId) {
        setTimeoutId(setTimeout(() => setPause(true), 3000));
      }
    }
    if (periodType === 1) {
      setPause(false)
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null)
      }
    }
  }, [periodType, dispatch, timeoutId])

  const endObject = useMemo(() => styles[styleCode].endObjectAfter ? <img
  className={b('end-image')}
  src={periodType === 2 && !isPause ? styles[styleCode].endObjectAfter : null}
  alt="" /> : null, [isPause, periodType, styleCode])

  return <article
      className={b({
        isAnimation: !isPause,
        isPauseAnimation: periodType === 2 && !isPause,
      })}
      ref={containerRef}
      style={{ background: `url(${styles[styleCode].background})`, backgroundSize: '200% 400%', backgroundPosition: '0 100%' }}>
        <div className={b('bg1', { isPauseAnimation: periodType === 2 && !isPause })} />
        <div className={b('bg2', { isPauseAnimation: periodType === 2 && !isPause })} />
        <div className={b('bg3', { isPauseAnimation: periodType === 2 && !isPause })}>
          <div className={b('stone')} />
          <div className={b('stone')} />
        </div>
        <div className={b('bg4', { isPauseAnimation: periodType === 2 && !isPause })} />
        <div className={b('bg5')} style={{ top: topParam }} key={`${topParam}A`} />
        <div className={b('bg6')} style={{ left: leftParam }} key={`${leftParam}B`} />

    {(isPause && periodType === 2) ? <Pause /> : <Firework isPause={isPause} />}
    {endObject}
  </article>;
}

export default GameWindow;