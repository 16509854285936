import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';
import dayjs from 'dayjs';

import DatePicker from 'components/DatePicker';
import Button from 'components/Button';

import { actions } from '../';
import closePng from './img/close.png';

import './FullMyBets.scss';

const b = block('full-my-bets');

const FullMyBets = ({ closeFull }) => {
  const locale = useSelector(state => state.locale.locale);
  const { myBets } = useSelector(state => state.history);
  const { roundID } = useSelector(state => state.game);
  const currency = useSelector(state => state.auth.currency);
  const dispatch = useDispatch();
  const [dates, setDates] = useState({from: dayjs().add(-2, 'day'), to: dayjs().add(1, 'day')});

  const getFullMyBets = useCallback(() => {
    dispatch(actions.getFullMyBets(dates.from.format('DDMMYYYY ZZ'), dates.to.format('DDMMYYYY ZZ')));
  },[dispatch, dates])

  useEffect(() => {
    getFullMyBets();
    // eslint-disable-next-line
  }, [roundID])

  const resultList = useMemo(() => (
    myBets.map((t, i) => (
      <tr key={`${t.value}${t.round_id}${i}`}>
        <td>
          {dayjs(t.created_at).format('YYYY-MM-DD HH:mm')}
        </td>
        <td className={b('amount')}>
          {(+t.bet_amount).toFixed(2)}
          <b>{` ${currency}`}</b>
        </td>
        <td className={b('amount', { isWin: t.win_amount > 0 })}>
          {t.win_amount > 0 ? (+t.win_amount).toFixed(2) : '0.00'}
          <b>{` ${currency}`}</b>
        </td>
        <td>
        <td>
          {t.auto_cashout_point ? t.auto_cashout_point : '-'}
        </td>
        </td>
        <td>
          {t.win_amount > 0 ? t.coefficient : '-'}
        </td>
        <td>
          {(t.crash_point)?.toFixed(2) || '-'}
        </td>
      </tr>
    ))
  ), [currency, myBets])

  const onFromChangeHandler = useCallback((newFromValue) => {
    setDates(prev => ({
      ...prev,
      from: newFromValue
    }));
  }, [setDates]);

  const onToChangeHandler = useCallback((newToValue) => {
    setDates(prev => ({
      ...prev,
      to: newToValue
    }));
  }, [setDates]);

  return <article className={b()}>
  <div className={b('bg')}>
    <div className={b('wrapper')}>
      <div className={b('content')}>
        <div className={b('header')}>
          {locale.myBets}
          <img className={b('close')} alt="" src={closePng} onClick={closeFull} />
        </div>
        <div className={b('filters')}>
          <div className={b('date-pickers-container')}>
            <div className={b('date-picker-container')}>
              <DatePicker 
                text={locale.fullMyBetsFrom} 
                value={dates.from} 
                onChange={onFromChangeHandler}
              />
            </div>
            <div className={b('date-picker-container')}>
              <DatePicker 
                text={locale.fullMyBetsTo} 
                value={dates.to} 
                onChange={onToChangeHandler}
              />
            </div>
          </div>
          <div className={b('filters-button-container')}>
            <Button 
              text={locale.filter}
              fontSize='smaller'
              color='grey'
              callBack={getFullMyBets}
            />
          </div>
        </div>
        <div className={b('body')}>
          <table className={b('table-header')}>
            <thead>
              <tr>
                <th>
                  {locale.start}
                </th>
                <th>
                  {locale.placeBet}
                </th>
                <th>
                  {locale.win}
                </th>
                <th>
                  {locale.autoCashout}
                </th>
                <th>
                  {locale.cashout}
                </th>
                <th>
                  {locale.finalNumber}
                </th>
              </tr>
            </thead>
          </table>
          <div className={b('table-body')}>
            <table>
              <tbody>
                {resultList}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</article>
}

export default FullMyBets;