import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';
import dayjs from 'dayjs';

import { actions } from '../';

import allBetsPng from './img/all-bets.png';

import './MyBets.scss';

const MyBets = ({ openFull }) => {
  const b = block('my-bets');
  const locale = useSelector(state => state.locale.locale);
  const lang = useSelector(state => state.locale.lang)
  const { myBets } = useSelector(state => state.history);
  const { roundID } = useSelector(state => state.game);
  const currency = useSelector(state => state.auth.currency);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getMyBets());
  }, [dispatch, roundID])

  const resultList = useMemo(() => (
    myBets.map((t, i) => (
      <div key={`${t.value}${t.round_id}${i}`} className={b('item', { isWin: t.win_amount > 0 })}>
        <div className={b('column')}>
          {dayjs(t.created_at).format('HH:mm:ss')}
        </div>
        <div className={b('column')}>
          {(+t.bet_amount).toFixed(2)}
        </div>
        <div className={b('column')}>
          {t.win_amount > 0 ? t.coefficient : '-'}
        </div>
        <div className={b('column')}>
          {t.win_amount > 0 ? (+t.win_amount).toFixed(2) : '-'}
        </div>
        <div className={b('column')}>
          {currency}
        </div>
      </div>
    ))
  ), [b, currency, myBets])

  return <section className={b()}>
    <div className={b('all-bets')} onClick={openFull}>
      <img className={b('icon')} src={allBetsPng} alt="" />
      {locale.myBetsHistory}
    </div>
    <div className={b('header', { lang })}>
      <div className={b('column')}>
        {locale.time}
      </div>
      <div className={b('column')}>
        {locale.amount}
      </div>
      <div className={b('column')}>
        {locale.odd}
      </div>
      <div className={b('column')}>
        {locale.win}
      </div>
      <div className={b('column')}>
        {locale.currency}
      </div>
    </div>
    <div className={b('items')}>
      {resultList}
    </div>
  </section>
}

export default MyBets;