import bgMusic from 'services/sounds/backgrounds';
import afterMusic from 'services/sounds/afters';

import * as dinoStyles from './dino';

const styles = {
  dino: {
    object: dinoStyles.object,
    background: dinoStyles.bg,
    after: dinoStyles.after,
    loading: dinoStyles.loading,
    loadingObject: dinoStyles.loadingObject,
    loadingHeight: '3.5rem',
    loadingObjectHeight: '6rem',
    loadingObjectLeft: '-3rem',
    notNeedRotate: true,
    isBig: true,
    isHorizon: true, 
    isCoefAfterToCenter: true,
    backgroundMusic: bgMusic.dino,
    afterMusic: afterMusic.dino,
    notNeedAfterAnimation: true,
    title: 'Dino',
  },
}

export default styles;