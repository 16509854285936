import React from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import Coef from './Coef';

import './Firework.scss';

const b = block('dino');

const Firework = () => {
  const periodType = useSelector(state => state.game.periodType);
  const coef = useSelector(state => state.game.coef);

  return periodType === 2 ? <React.Fragment>
    <div className={b('crash-wrapper')}>
      <div className={b('crash')} />
    </div>
    <div className={b('result')}>
      {coef}X
    </div>
    </React.Fragment> : <div className={b()}>
    <div className={b('wrapper')}>
      <div className={b('animation')} />
    </div>
    <Coef />
  </div>
}

export default Firework;