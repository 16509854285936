import BaseApi from '../BaseApi';

class HistoryApi extends BaseApi {
  constructor(baseUrl) {
    super();
    this.baseUrl = baseUrl;
  }

  getTopWins = () => this.sendQuery(
    this.queryTypes.GET,
    `${this.baseUrl}/top_wins`,
  )

  getMyBets = (count = 10, token) => this.sendQuery(
    this.queryTypes.GET,
    `${this.baseUrl}/bet_history?bet_quantity=${count}&token=${token}`,
  )

  getFullMyBets = (bet_quantity = 10, token, from_date, to_date) => this.sendQuery(
    this.queryTypes.POST,
    `${this.baseUrl}/filtered_bet_history?token=${token}`,
    {
      bet_quantity,
      from_date,
      to_date,
    }
  )

}

export default HistoryApi;