import React, { useState, useEffect, useCallback } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { actions as settingsActions } from 'features/Settings';

import arrowSvg from './img/arrow.svg';
import musicOnPng from './img/music-on.png';
import musicOffPng from './img/music-off.png';
import rulesPng from './img/rules.png';

import './Header.scss';

const startTime = +dayjs();

const b = block('header');

const Header = ({ openRules }) => {
  const dispatch = useDispatch();
  const [, setSeconds] = useState(0);
  const isMusic = useSelector(state => state.settings.isMusic);
  const currency = useSelector(state => state.auth.currency);
  const balance = useSelector(state => state.auth.balance);
  const { locale } = useSelector(state => state.locale);

  const switchMusic = useCallback(() => dispatch(settingsActions.setMusic(!isMusic)), [dispatch, isMusic]);

  const setNewSeconds = useCallback(() => setSeconds(state => state + 1), [])

  useEffect(() => {
    setInterval(() => {
      setNewSeconds();
    }, 1000)
    // eslint-disable-next-line 
  }, [])

  return <header className={b()}>
    <div className={b('wrapper')}>
      <div className={b('content')}>
        <div className={b('title')}>Dino</div>
        <div className={b('currencies-wrapper')}>
          <div className={b('currencies')}>
            <div className={b('balance')}>{(+balance).toFixed(2)}</div>
            <div className={b('currency')}>{currency}</div>
            <SVGInline className={b('currency-arrow').toString()} svg={arrowSvg} />
          </div>
        </div>
        <div className={b('right')}>
          <div className={b('time')}>{dayjs((+dayjs() - startTime)).add(-3, 'hour').format('HH:mm:ss')}</div>
          <img className={b('music-icon')} src={isMusic ? musicOnPng : musicOffPng} alt="" onClick={switchMusic} />
          <div className={b('rules')} onClick={openRules}>
            <img className={b('rules-icon')} src={rulesPng} alt="" />
            <div className={b('rules-text')}>
              {locale.rules}
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
}

export default Header;