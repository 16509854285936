import React, { useMemo, useEffect } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';

import { actions } from '../';

import './TopWins.scss';

const TopWins = () => {
  const dispatch = useDispatch();
  const b = block('top-wins');
  const locale = useSelector(state => state.locale.locale);
  const topWins = useSelector(state => state.history.topWins);

  useEffect(() => {
    dispatch(actions.getTopWins());
  }, [dispatch])

  const resultList = useMemo(() => (
    topWins.map((t, i) => (
      <div key={`${t.user_id}${i}`} className={b('item', { isWin: t.isWin })}>
        <div className={b('column')}>
          {t.nick || t.user_id}
        </div>
        <div className={b('column')}>
          {(+t.bet_amount).toFixed(2)}
        </div>
        <div className={b('column')}>
          {(+t.coefficient).toFixed(2) || '-'}
        </div>
        <div className={b('column')}>
          {t.win_amount ? (+t.win_amount).toFixed(2) : '-'}
        </div>
        <div className={b('column')}>
          {t.currency}
        </div>
      </div>
    ))
  ), [b, topWins])

  return <section className={b()}>
    <div className={b('header')}>
      <div className={b('column')}>
        {locale.player}
      </div>
      <div className={b('column')}>
        {locale.amount}
      </div>
      <div className={b('column')}>
        {locale.odd}
      </div>
      <div className={b('column')}>
        {locale.win}
      </div>
      <div className={b('column')} />
    </div>
    <div className={b('items')}>
      {resultList}
    </div>
  </section>
}

export default TopWins;