import BaseApi from '../BaseApi';

class GameApi extends BaseApi {
  constructor(baseUrl) {
    super();
    this.baseUrl = baseUrl;
  }

  placeBet = (token, info) => this.sendQuery(
    this.queryTypes.POST,
    `${this.baseUrl}/place_bet?token=${token}`,
    info,
  )

  cashoutBet = (token, info) => this.sendQuery(
    this.queryTypes.POST,
    `${this.baseUrl}/cash_out?token=${token}`,
    info,
  )

}

export default GameApi;