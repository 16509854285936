import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import eggPng from '../img/egg.png';

import './Pause.scss';

const b = block('pause');

const stepAmount = 25;

const Pause = () => {
  const { restartDuration } = useSelector(state => state.game)
  const { locale } = useSelector(state => state.locale);
  const [timePercent, setTimePercent] = useState(0);
  
  const timeUpdate = useCallback(() => {
    setTimePercent(state => {
      return state + Math.round((state + (1 / stepAmount) * 100)) > 100 ? 100 : state + Math.round((state + (1 / stepAmount) * 100))
    });

  }, [setTimePercent])

  useEffect(() => {
    setInterval(() => timeUpdate(), (restartDuration - 3000) / stepAmount)
  }, [timeUpdate, restartDuration])

  return <article className={b()}>
    <img src={eggPng} className={b('egg')} alt="" />
    <div className={b('loader')}>
      <div className={b('loader-value')} style={{ animationDuration: `${(restartDuration - 3000) / 1000}s` }} />
    </div>
    <div className={b('percent')}>
      {timePercent}%
    </div>
    <div className={b('text')}>
      {locale.nextRound}
    </div>
  </article>
}

export default Pause;