import React from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';

import closePng from './img/close.png';

import './Rules.scss';

const Rules = ({ close }) => {
  const b = block('rules');
  const currency = useSelector(state => state.auth.currency);
  const { limits } = useSelector(state => state.game);

  return <article className={b()}>
    <div className={b('bg')}>
      <div className={b('wrapper')}>
        <div className={b('content')}>
          <div className={b('header')}>
            <img className={b('close')} alt="" src={closePng} onClick={close} />
          </div>
          <div className={b('body')}>
            <div className="popup-rules-title-1">GET A SUPER EXCITING EXPERIENCE WITH CHANCES OF ALL OR NOTHING!</div>
            <div className="popup-rules-title-2 pn-semibold">It’s easy to play and fun for people taking risks!</div>
            <div className="popup-rules-title-3 pn-semibold">Here we have a running Dino. Multiple players place bets and try to collect money before the asteroid falls on Dino. As time goes, the odds get higher.</div>
            <div className="popup-rules-title-4">HAVE A LUCKY TRY!</div>
            <div className="popup-rule-bullets pn-semibold">
              <div className="popup-rule-bullets-left">
                <div className="popup-rule-bullet-item popup-rule-bullet-left-item-text">To win, your cashout number must be greater than 1 and less or equal to crash number. The win is calculated by multiplying collected multiplier coefficient and the made bet.</div>
                <div className="popup-rule-bullet-item">Maximum multiplier is 10000x. minimum is 1x</div>
                <div className="popup-rule-bullet-item">You can make one or two bet on this same game.</div>
                <div className="popup-rule-bullet-item">You can configure each bet manually and then collect each bet separately.</div>
                <div className="popup-rule-bullet-item">You can set auto collect on each bet. enter number greater than 1 and set "auto" on. if dino is not crashed before that number, your win amount will be collected automatically.</div>
                <div className="popup-rule-bullet-item">You can set auto bet for each bet and bet will be made automatically for every spin, until you   disable it.</div>
                <div className="popup-rule-bullet-item"></div>
                <div className="popup-rule-bullet-item"></div>
                <div className="popup-rule-bullet-item"></div>
                <div className="popup-rule-bullet-item"></div>
                <div className="popup-rule-bullet-item"></div>
                <div className="popup-rule-bullet-item"></div>
              </div>
              <div className="popup-rule-bullets-right">
                <div className="popup-rule-bullet-item popup-rule-bullet-right-item-text">By clicking on crash number history you will see the last 100 crash number.</div>
                <div className="popup-rule-bullet-item">In "My Bets" section you can see your betting history of last 90 days.</div>
                <div className="popup-rule-bullet-item">In "HighRollers" section you can see top 20 Highest profitable bets from all users, during last 24 hour</div>
                <div className="popup-rule-bullet-item">In case of system failure, all bets will be refunded.</div>
                <div className="popup-rule-bullet-item">In "Current Bets", "My Bets", "HighRollers" sections, Coef Column is just calculated by dividing Win to bet. if profit exceeds Limits then "Coef" value will be different from Cashout number.</div>
                <div className="popup-rule-bullet-item">Please note that the players are encouraged to use auto-cashout option in circumstances where players anticipate poor internet connectivity or poor device performance</div>
                <div className="popup-rule-bullet-item"></div>
                <div className="popup-rule-bullet-item"></div>
                <div className="popup-rule-bullet-item"></div>
                <div className="popup-rule-bullet-item"></div>
                <div className="popup-rule-bullet-item"></div>
                <div className="popup-rule-bullet-item"></div>
              </div>
            </div>
            <div className="popup-rules-limits-title pn-semibold">Limits</div>
            <div className="popup-rules-limits-table">
              <div className="popup-rules-limits-header">
                <div className="popup-rule-limit-header-item">Currency</div>
                <div className="popup-rule-limit-header-item">Min bet</div>
                <div className="popup-rule-limit-header-item">Max bet</div>
                <div className="popup-rule-limit-header-item">Max profit cap</div>
              </div>
              <div className="popup-rules-limits-body">
                <div className="popup-rule-limit-body-item">{currency}</div>
                <div className="popup-rule-limit-body-item">{`${(+limits.min).toFixed(2)} `}</div>
                <div className="popup-rule-limit-body-item">{`${(+limits.max).toFixed(2)} `}</div>
                <div className="popup-rule-limit-body-item">{`${(+limits.win).toFixed(2)} `}</div>
              </div>
            </div>
            <div className="popup-rules-rtp-bullet pn-semibold">Range of RTP (Return to player) offered: 95.0-95.9%. Maximum RTP can be achieved by choosing a multiplier before or equal to 1.12x, such that the potential win is less than the maximum profit cap offered.</div>
          </div>
        </div>
      </div>
    </div>
  </article>
}

export default Rules;