import React, { useEffect, useState, useCallback, Suspense } from 'react';
import block from 'bem-cn';
import { Route, Switch, useLocation, useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { languages } from 'services/locale';
import Header from 'components/Header';
import { actions as authAction } from './features/Auth';
import { actions as localeActions } from './features/locale';
import Notify from 'features/Notify/view';
import MusicWindow from 'components/Music/MusicWindow';
import { Rules } from './components/Rules';
import Loader from 'components/Loader';
import { Game } from './features/Game';

import './App.scss';

const Music = React.lazy(() => import('./components/Music'));

const App = ({ lang, homeUrl }) => {
  const b = block('app');
  const [isUserActivity, setUserActivity] = useState(false);
  const [isOpenRules, setOpenRules] = useState(false);
  const [isOpenMusicWindow, setOpenMusicWindow] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const isAuth = useSelector(state => state.auth.isAuth);
  const tempToken = useSelector(state => state.auth.token);
  const tempHomeUrl = useSelector(state => state.auth.homeUrl);
  const tempLang = useSelector(state => state.locale.lang);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isGameSocketError } = useSelector(state => state.socketError);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1200);
  }, [])

  const userActivityClick = useCallback(f => isUserActivity ? f :
    setUserActivity(true),
  [isUserActivity]);

  useEffect(() => {
    if (location.search.indexOf(tempToken) === -1 && tempToken !== null) {
      history.replace(`${location.pathname}?token=${tempToken}&lang=${tempLang}&homeUrl=${tempHomeUrl}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search, tempToken])

  useEffect(() => {
    dispatch(authAction.setPlatform());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator.userAgent, dispatch])

  useEffect(() => {
    dispatch(localeActions.changeLang(languages[lang] ? lang : 'fr'))
  }, [dispatch, lang])

  useEffect(() => {
    dispatch(authAction.setHomeUrl(homeUrl))
  }, [dispatch, homeUrl])

  return isGameSocketError ? <Loader /> : (
    <React.Fragment>
      {!isAuth || isLoading ? <Loader /> :
      <div className={b()} onMouseMove={userActivityClick} onTouchMove={userActivityClick} >
        {isOpenRules && <Rules close={() => setOpenRules(false)} />}
        {isOpenMusicWindow && <MusicWindow closeMusicWindow={() => setOpenMusicWindow(false)} />}
        <Header openRules={() => setOpenRules(true)} />
        <Notify />
        <Switch>
          <Redirect from="/" to="/game" exact />
          <Route exact key="/" path="/" component={Game} />
          <Route exact key="/game" path="/game" component={Game} />
          {/* <Route exact key="/rules" path="/rules" component={Rules} /> */}
        </Switch>
      </div>}
      {isUserActivity && <Suspense fallback={null}>
        <Music />
      </Suspense>}
    </React.Fragment>

  );
}

export default App;
