import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import Basket from '../Basket';

const Model = styled.article`
  width: 100%;
  height: 100%;
  @media (max-width: 991.98px) {
    height: unset;
  }
  @media screen and (min-width: 700px) {
    display: flex;
  }
  @media screen and (max-width: 1440px) {
    flex-direction: column;
  }
`

const BasketModel = () => {
  const [baskets, setBaskets] = useState([true, true])

  const changeBasket = useCallback(index => {
    const basketsLength = baskets.filter(t => t === true).length;
    if (basketsLength === 2) { // Remove basket
      setBaskets([...baskets.map((t, i) => i === index ? false : t)])
    } else { // Add basket
      setBaskets([...baskets, true])
    }
  }, [baskets])

  const basketsView = useMemo(() =>
    baskets.map((t, i) => t ? <Basket key={i} index={i} callback={() => changeBasket(i)} isAlone={baskets.filter(t => t === true).length === 1} /> : null),
    [baskets, changeBasket]
  );

  return <Model>
     {basketsView}
  </Model>
}

export default BasketModel;