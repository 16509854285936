import { rules } from './rules';

export const locale = {
  // Advice
  editAdvice: 'You can set a nickname and avatar by clicking on the pencil',

  //General
  id: 'Id',
  ok: 'Ok',
  amount: 'Place Bet',

  //Main
  maxWin: 'Max profit',
  minBet: 'Min Bet',
  maxBet: 'Max Bet',
  playNow: 'Play now',
  topWins: 'Highrollers',
  roundID: 'Round ID',

  //Game
  min: 'Min',
  max: 'Max',
  placeBet: 'Place Bet',
  bet: 'Bet',
  noName: 'No name',
  nextRound: 'Place your bets',
  
  //History
  bets: 'Current bets',
  myBets: 'My bets',
  results: 'Results',
  betAmount: 'Bet amount',
  youWon: 'Victory! Your balance:',
  odd: 'Coef',
  win: 'Win',
  time: 'Time',
  player: 'User',
  myBetsHistory: 'My Bets History',
  currency: 'Currency',
  start: 'Start',
  finalNumber: 'Final Number',
  filter: 'Filter',
  fullMyBetsFrom: 'from',
  fullMyBetsTo: 'to',

  // Statistic
  gameHistory: 'Game History',
  lastNumbers: 'Last 100 Number',

  //Auth
  userUpdateSuccess: 'Data updated!',
  userUpdateFail: 'An error has occurred. Please try a different nickname or update the data later.',

  //Chat
  chat: 'Chat',
  online: 'Online',
  enterMessage: 'Type a message',
  openChat: 'Open chat',
  closeChat: 'Close chat',

  //Basket
  autoCashout: 'Auto Cashout',
  autoBet: 'Auto bet',
  allIn: 'All in',
  collect: 'Collect',
  placedBet: 'Bet placed',
  placeBetNextRound: 'Next Round Bet',
  cashout: 'Cashout',
  cancelBet: 'Cancel',
  canceledBet: 'Your bet has been successfully canceled',
  betLimit: (amount, currency) => `Your bet for this round should not exceed ${amount} ${currency}`,

  // Settings
  music: 'Music',
  likeSound: 'Would you like sound?',
  yes: 'Yes',
  no: 'No',

  // Jackpot
  won: 'won',
  anotherWinnerNotify: (winner_name, jackpot_name, winner_amount, currency) => `Player ${winner_name} won a ${jackpot_name} of ${winner_amount} ${currency}`,
  congratulations: 'Congratulations',
  youWonJackpot: `You've won`,

  //Rules
  rules: 'Game Rules',
  howToPlay: 'How to play?',
  detailedRules: 'Detailed rules',
  rule1: 'BET before the start of the round',
  rule2: 'WATCH as Crash grow and your winnings increase',
  rule3: 'CASHOUT before Crash disappears and win X more!',
  rulesHtml: rules,
}