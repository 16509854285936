import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import openPng from './img/chat-open.png';

import './Statistic.scss';

const colors = [
  { min: 0, max : 1.5, color: '#ff3154' },
  { min: 1.5, max : 100000000000, color: '#a1f15b' },
]

const Statistic = () => {
  const b = block('statistic');
  const coefsHistory = useSelector(state => state.game.coefsHistory);
  const platform = useSelector(state => state.auth.platform);
  const [isOpen, setOpen] = useState(false);
  const locale = useSelector(state => state.locale.locale);

  const coefs = useMemo(() => coefsHistory.filter((t, i) => i < (platform === 'mobile' ? 30 : 100)).map(t => <div
    className={b('coef')}
    key={t.game_id}
    style={{ color: colors.find(tempColor => t.crash_point >= tempColor.min && t.crash_point < tempColor.max)?.color || '#a1f15b' }}>
      {(+t.crash_point).toFixed(2)}
  </div>), [b, coefsHistory, platform])

  return <section className={b({ isOpen })}>
    <div className={b('coefs-container')} onClick={() => setOpen(!isOpen)}>
      {coefs}
    </div>
    <div className={b('icon-container')}>
      <img src={openPng} alt="" className={b('icon')} onClick={() => setOpen(!isOpen)} />
    </div>
    {isOpen && <div className={b('all-coefs')}>
      <div className={b('all-coefs-top')}>
        <div className={b('title')}>{locale.gameHistory}</div>
        <div className={b('last')}>{locale.lastNumbers}</div>
        <img src={openPng} alt="" className={b('icon', { isRotate: true })} onClick={() => setOpen(!isOpen)} />
      </div>
      <div className={b('all-coefs-container')}>
        {coefs}
      </div>
    </div>}
  </section>
}

export default Statistic;