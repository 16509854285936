import React, { useCallback } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';

import Input from 'components/Input';
import calendar from './img/calendar.png';

import './DatePicker.scss';

const b = block('date-picker');

const DatePicker = ({ text, value, onChange }) => {

  const changeHandler = useCallback(newValue => {
    const dayjsValue = dayjs(newValue, 'YYYY-MM-DD');
    onChange(dayjsValue);
  }, [onChange]);

  return (
    <div className={b()}>
      <div>{text}</div>
      <Input
        type='date'
        className={b('input')}
        value={value.format('YYYY-MM-DD')}
        callBack={changeHandler}
      />
      <img className={b('img')} src={calendar} alt='calendar' />
    </div>
  );
};

export default DatePicker;